import React from "react"
import styled, { css } from "styled-components"
import {
  perfectFourth,
  neutral,
  teal,
  navy,
  transition,
  breakpoint,
} from "../../utils"

const FormsCourseForm = ({ button }) => {
  return (
    <FormWrapper>
      <form
        className="flow"
        name="forms-course"
        method="POST"
        netlify-honeypot="bot-field"
        data-netlify="true"
        action="/success"
      >
        <input type="hidden" name="bot-field" />
        <input type="hidden" name="form-name" value="forms-course" />
        <input
          type="text"
          name="name"
          placeholder="Enter your first name"
          required
        />
        <input
          type="email"
          name="email"
          placeholder="Enter your email"
          required
        />
        <button type="submit">{button}</button>
      </form>
    </FormWrapper>
  )
}

export const DefaultInput = css`
  border-radius: 50px;
  display: block;
  inline-size: 100%;
  block-size: ${perfectFourth[700]};
  padding-inline-start: ${perfectFourth[200]};
  transition: ${transition.reg};
`

export const FormWrapper = styled.div`
  --flow-space: ${perfectFourth[200]};
  max-inline-size: 410px;
  @media ${breakpoint.md} {
    max-inline-size: 300px;
  }
  margin-inline-start: auto;
  margin-inline-end: auto;
  input {
    ${DefaultInput}
    border: 2px solid ${neutral[200]};
    padding-inline-start: ${perfectFourth[500]};
    color: ${navy[600]};
    &::placeholder {
      color: ${navy[400]};
    }
    &:hover, &:focus, &:active {
      border: 2px solid ${neutral[500]};
    }
  }
  button {
    ${DefaultInput}
    border: none;
    background: ${teal[500]};
    color: ${navy[600]};
    cursor: pointer;
    &:hover {
      background: ${teal[400]};
      color: ${navy[700]};
    }
  }
`

export default FormsCourseForm
