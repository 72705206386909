import React from "react"
import Layout from "../../components/Layout"
import { HeroWrapper, TextWrapper } from "../../components/HeroSecondary"
import { ContentWrapper } from "../../components/Layout"
import { HeaderOne } from "../../components/Headers"
import SEO from "../../components/seo"
import { graphql, useStaticQuery } from "gatsby"
import styled from "styled-components"
import { breakpoint } from "../../utils"
import Image from "gatsby-image"
import Breadcrumb from "../../components/Breadcrumb"
import FormsCourseForm from "../../components/Forms/FormsCourseForm"

const BeautifulForms = () => {
  const data = useStaticQuery(courseImage)
  return (
    <>
      <SEO title="CSS Course on Creating Beautiful and Accessible Forms" />
      <Layout>
        <ContentWrapper>
          <Breadcrumb
            subPageName="Academy"
            subPagePath="/academy/"
            pageName="Create Beautiful Forms"
          />
          <HeroWrapper>
            <TextWrapper>
              <div className="flow">
                <HeaderOne>
                  Create Beautiful Forms
                  <span>.</span>
                </HeaderOne>
                <p>
                  Learn how to create and layout beautiful and useable forms, as
                  well as all the mini interactions that will make your forms
                  feel polished and a pleasure to complete. Sign up below to get
                  more information.
                </p>
                <FormsCourseForm button="Learn More" />
              </div>
            </TextWrapper>
            <ImageWrapper>
              <Image
                fixed={data.file.childImageSharp.fixed}
                alt="Create Beautiful and Accessible Forms: A visual of all the forms you can create when you sign up"
              />
            </ImageWrapper>
          </HeroWrapper>
        </ContentWrapper>
      </Layout>
    </>
  )
}

const ImageWrapper = styled.div`
  overflow: hidden;
  @media ${breakpoint.md} {
    display: none;
  }
`

const courseImage = graphql`
  {
    file(relativePath: { eq: "form-course-examples.png" }) {
      childImageSharp {
        fixed(width: 690, quality: 100) {
          ...GatsbyImageSharpFixed
        }
      }
    }
  }
`

export default BeautifulForms
